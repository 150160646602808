import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import HomeView from '../pages/custom/Home.vue'
import AboutView from '../views/AboutView.vue'
import TodosView from '../views/TodosView.vue'
//import JobsView from '../views/Jobs'
//import CoursesView from '../views/Courses'
import LoginView from '../pages/base/Login'

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  {
    path: '/login',
    name: 'login',
    sectionTitle:'Login',
    loggedOut: true,
    component: LoginView
  },
  
  { path: '/profile', name:'profile', title: 'Profiel', component: () => import('../pages/base/User/Profile') },
  { path: '/profile/password', name:'profile_password', title: 'Wachtwoord', component: () => import('../pages/base/User/Password') },

  // TOOLS
  { path: '/tools', icon:'fa-gear',sectionTitle:'Tools', name:'tools_mails', title: 'Mails', component: () => import('../pages/base/Tools/Mails') },
  { path: '/tools/log', name:'tools_lot', title: 'Log', component: () => import('../pages/base/Tools/Log') },
  { path: '/tools/export', name:'tools_export', title: 'Exporteren', component: () => import('../pages/custom/Tools/Export') },
  { path: '/tools/permissions',   name:'tools_permissions', title:"Permissions", component: () => import('../pages/base/Tools/Permissions') },
  { path: '/tools/database', name:'tools_database', title: 'Database', component: () => import('../pages/base/Tools/Database') },
  { path: '/tools/migrate', name:'tools_migrate', title: 'Migrate', component: () => import('../pages/custom/Tools/Migrate') },
  { path: '/tools/cache', name:'tools_cache', title: 'Cache', component: () => import('../pages/base/Tools/Cache') },
  { path: '/tools/test', name:'tools_test', title: 'Test', component: () => import('../pages/custom/Tools/Test') },
  
  // PRODUCTS
  { path: '/products', icon:'fa-cart-shopping',sectionTitle:'Producten', name:'products_list', title: 'Overzicht', component: () => import('../pages/custom/Product/List') },
  { path: '/products/updates', name:'products_updates', title: 'Updates', component: () => import('../pages/custom/Product/Updates') },
  { path: '/products/imageerrors', name:'products_updates', title: 'Afbeelding fouten', component: () => import('../pages/custom/Product/ImageErrors') },
  { path: '/products/import', name:'products_import', title: 'Import', component: () => import('../pages/custom/Product/Import') },

  // MEMBERS
  { path: '/members', icon:'fa-people-group',sectionTitle:'Members', name:'members_list', title: 'Overzicht', component: () => import('../pages/custom/Member/List') },
  
  
  // SHOPS
  { path: '/shops', icon:'fa-store',sectionTitle:'Winkels', name:'shops_list', title: 'Overzicht', component: () => import('../pages/custom/Shop/List') },
  
  // CATEGORIES
  { path: '/productcategories', icon:'fa-list-tree',sectionTitle:'Categorieën', className:'hasFileTree', name:'productcategories_overview', title: 'Overzicht', component: () => import('../pages/custom/Productcategory/Overview') },

  
// SETTINGS
{ path: '/settings', sectionTitle: 'Instellingen', icon:'fa-gears', name:'settings', title:"Variabelen", component: () => import('../pages/base/Settings/Overview') },
{ path: '/settings/mailstatus', name:'settings_mailstatus', title:"Mail status", component: () => import('../pages/base/Settings/Mailstatus') },
{ path: '/settings/mailtemplates', name:'settings_mailtemplates', title:"Mail templates", component: () => import('../pages/base/Settings/Mailtemplates') },
{ path: '/settings/msgraph', name:'settings_msgraph', title:"Microsoft", component: () => import('../pages/base/Settings/MSGraph') },


 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
  document.title = process.env.VUE_APP_COMPANY_NAME;
  next();
});

export default router
